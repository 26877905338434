import React, { useEffect } from "react"
import { graphql } from "gatsby"
import Metadata from '../components/Metadata'
import Layout from "../components/Layout"
import { FullWidthAd } from '../components/AdComponent'
import '../pages/blog.css'
import { LogPageView } from "../components/LogEvents"
import SharingButtons from "../components/sharingButtons"
import { Disqus } from 'gatsby-plugin-disqus'
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';

export default ({ location, data }) => {  
  const {changeLanguage} = useI18next();
  const {t} = useTranslation();
  const post = data.post

  // Show ad 25% of the time
  // const showAdd = Math.random() < 0.25 ? true : false;
  const showAdd = true;

  useEffect(() => {
    // changeLanguage("fr");
  },[showAdd])
  
  return (
    <Layout>
      <LogPageView event="pageview" slug={location.pathname} itemName="BlogPost" value={null} itemId={null} />
      <Metadata
        type="post"
        pathname={location.pathname}
        title={post.frontmatter.title}
        description={post.excerpt}
        date={post.frontmatter.rawDate}
        update={post.frontmatter.rawUpdate}
        image={post.frontmatter.image || null}
      />
    <div className="bg-light">
      <div className="mx-auto px-2 py-md-3 px-md-5 mb-2 bg-white blog-post">
        {/* Most profitable ad */}
        <FullWidthAd client="ca-pub-3093835180445554" slot="1381029230"/>


        
        {/* <AmazonAd/> */}

        {/* <div className="text-center pt-3">
          <iframe className="d-none d-md-flex m-auto" src="https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=48&l=ez&f=ifr&linkID=84b1dc7f5bbcca75cb638d43574c88bc&t=cinetimes0f-21&tracking_id=cinetimes0f-21" width="728" height="90" scrolling="no" border="0" marginwidth="0" style={{border:"none"}} frameborder="0"></iframe>
          <iframe className="d-none d-sm-flex d-md-none m-auto" src="https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=13&l=ez&f=ifr&linkID=9f8465c575575120298f9f992fd9ac91&t=cinetimes0f-21&tracking_id=cinetimes0f-21" width="468" height="60" scrolling="no" border="0" marginwidth="0" style={{border:"none"}} frameborder="0"></iframe>
          <iframe className="d-flex d-sm-none m-auto" src="https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=42&l=ez&f=ifr&linkID=3fef2f44e2448269b676133571ae0191&t=cinetimes0f-21&tracking_id=cinetimes0f-21" width="234" height="60" scrolling="no" border="0" marginwidth="0" style={{border:"none"}} frameborder="0"></iframe>
        </div> */}

        <div class="col-xs-12 px-2 py-3 py-md-0 px-md-5 mb-2">
          <h1 class="text-left my-3 mt-md-5 font-weight-bold"> 
          {post.frontmatter.title}
              <a href=""  class="ml-2"> <span class="far fa-edit fa-xs text-dark"></span> </a>
              <br />
          </h1>
          <span class="text-muted">
            {!post.frontmatter.update ? post.frontmatter.date : `${t('Mis à jour le')} ${post.frontmatter.update}`}
          </span>
          <SharingButtons title={post.frontmatter.title} url={"https://cinetimes.org"+location.pathname} />
        </div>

        <div class="floating-share">
          {/* <%- partial('_partial/sticky-social-bar', {item: page}) %> */}
        </div>

        { showAdd ? 
          // <div class="mx-auto py-md-3 px-md-5 mb-2 blog-post">
          //   <FullWidthAd client="ca-pub-3093835180445554" slot="1381029230"/>
          // </div>
          // <div>
          //   <div className="text-center pt-3">
          //     <iframe className="d-none d-md-flex m-auto" src="https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=48&l=ur1&category=prime_video&banner=1WPM16T3GQNXXGR11A02&f=ifr&linkID=fc5e64c4bfe268b6de1be4676429a56b&t=cinetimes0f-21&tracking_id=cinetimes0f-21" width="728" height="90" scrolling="no" border="0" marginwidth="0" style={{border:"none"}}  frameborder="0"></iframe>
          //     <iframe className="d-none d-sm-flex d-md-none m-auto" src="https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=26&l=ur1&category=prime_video&banner=178NVA4EJZGQWMBF67R2&f=ifr&linkID=e1c3761e4a25e8f110eb494385ea66a8&t=cinetimes0f-21&tracking_id=cinetimes0f-21" width="468" height="60" scrolling="no" border="0" marginwidth="0" style={{border:"none"}}  frameborder="0"></iframe>
          //     {/* <iframe className="d-flex d-sm-none m-auto"  src="https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=288&l=ur1&category=prime_video&banner=1MMB6DFN40NE65X5R3R2&f=ifr&linkID=0e0bd4fee9c649ebb54e201bd4668d03&t=cinetimes0f-21&tracking_id=cinetimes0f-21" width="320" height="50" scrolling="no" border="0" marginwidth="0" style={{border:"none"}}  frameborder="0"></iframe> */}
          //     <iframe className="d-flex d-sm-none m-auto"  src="https://rcm-eu.amazon-adsystem.com/e/cm?o=8&p=12&l=ur1&category=prime_video&banner=1AMG5VP0PM1EHJKZG002&f=ifr&linkID=606b9a4ae2b28eb7888b2c68f64296bf&t=cinetimes0f-21&tracking_id=cinetimes0f-21" width="300" height="250" scrolling="no" border="0" marginwidth="0" style={{border:"none"}}  frameborder="0"></iframe>
          //   </div>
          // </div>
          ''
          :
          ''
        }

        <div class="col-md-8 col-xs-12 mx-auto py-3 px-md-5 px-xs-1 mb-2 bg-color-white blog-post">
          <div dangerouslySetInnerHTML={{ __html: post.html }} class="mb-5"/>
          <h6>{t('Partagez cet article')}:</h6>
          <SharingButtons title={post.frontmatter.title} url={"https://cinetimes.org"+location.pathname} />
        </div>

        {/* <div class="mx-auto py-md-3 px-md-5 mb-2 bg-color-white blog-post">
          <FullWidthAd client="ca-pub-3093835180445554" slot="1381029230"/>
        </div> */}


          {/* <div class="d-flex justify-content-end">
            <div class="text-right bottom-share">
                <%- partial('_partial/share', {item: page}) %>
            </div>
          </div> */}
        </div>

    </div>

    <div className="col-md-8 col-xs-12 mx-auto py-3 px-md-5 px-sm-1 mb-5">
      <Disqus  config={{ url: `https://cinetimes.org/${location.pathname}`, identifier: post.frontmatter.title, title: post.frontmatter.title}} />
    </div>

      {/* <div class="col-md-8 col-xs-12 mx-auto py-3 px-md-5 px-sm-1 mb-5">
          <%- partial('_partial/comment-list') %>
          </div>
          
          <div class="col-md-8 col-xs-12 mx-auto py-3 px-md-5 px-sm-1 mb-5">
          <%- partial('_partial/comment-form') %>
        </div> */}
        
      {/* Responsive blog */}
      {/* <FullWidthAd client="ca-pub-3093835180445554" slot="1381029230"/> */}

    </Layout>
  )
}

export const query = graphql`  
  query($slug: String!, $language: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      excerpt
      frontmatter {
        title
        date(locale: $language)
        update(locale: $language)
        rawDate: date
        rawUpdate: update
      }
    }
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

